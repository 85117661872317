<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                :disabled="isReadOnly('name')"
                label="Имя"
                name="indicatorName"
                persistent-hint
                hint="имя индикатора"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.weight"
                :disabled="isReadOnly('weight')"
                type="number"
                label="weight"
                name="indicatorWeight"
                persistent-hint
                hint="weight"
                @input="$emit('update:weight', Number($event))"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.skillAssessmentId"
                :items="assessments"
                :filter="customFilter"
                :disabled="isReadOnly('skillAssessmentId')"
                item-text="name"
                item-value="id"
                label="Опрос"
                name="skillAssessmentId"
                persistent-hint
                hint="Опрос индикатора"
                @input="$emit('update:skillAssessmentId', $event)"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.description"
                :disabled="isReadOnly('description')"
                label="description"
                name="indicatorDescription"
                persistent-hint
                hint="description"
                @input="$emit('update:description', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'IndicatorForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    assessments: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание индикатора';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование индикатора';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },

    isReadOnly(key) {
      return this.readOnly.includes(key);
    },
  },
};
</script>
